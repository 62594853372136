import getCandidateRequisitionByRequisitionIdAndCandidateEmail from "../../apis/getCandidateRequisitionByRequisitionIdAndCandidateEmail/getCandidateRequisitionByRequisitionIdAndCandidateEmail";
import { codeTofield, filterFunc } from "../GlobalAssistingFunctions/GlobalAssistingFunctions";
import getDynamicFieldsByStatus from "../../apis/getAllDynamicStatusFields/getDynamicFieldsByStatus";
import { DateTime } from "luxon";

export const createEventStatus = (payloadData, userId, fields) => {

    let activityLogs = updateEventStatus(payloadData, userId, fields)

    return {
        candidateStatusCode: payloadData?.status?.candidateStatusCode,
        candidateStatusCodeId: payloadData?.status?.candidateStatusCodeId,
        created_at: DateTime.now().toISO(),
        createdBy: userId,
        activityLogs: [activityLogs],
        isDeleted: false,
        isCurrentlyUsed: true,
        isDeletedlog: [],
        isCurrentlyUsedlog: [payloadData.displayDate]
    }
};

// export const updateEventStatus = (eventStatus, payloadData, userId) => {
//     eventStatus.updated_at = DateTime.now().toISO();
//     eventStatus.isDeleted = false;
//     eventStatus.isCurrentlyUsed = true;
//     eventStatus.isDeletedlog = [];

//     eventStatus.activityLogs.push({
//         displayDate: DateTime.now().toISO(),
//         eventDate: payloadData.eventDate || null,
//         notes: payloadData.notes,
//         salary: payloadData.salary,
//         hourlyMargin: payloadData.hourlyMargin,
//         endDate: payloadData.endDate,
//         hourlyPayRate: payloadData.hourlyPayRate,
//         contractualType: payloadData?.contractualType || undefined,
//         submissionStatus: payloadData?.submissionType || null,
//         updatedBy: userId,
//     });
// };

export const updateEventStatus = (payloadData, userId, fields) => {
    let activityLogs = {}

    for (let field of fields?.fields) {
        let val = field?.dataType !== 'Number' ? payloadData[field?.altName] : Number(payloadData[field?.altName])
        activityLogs[field?.altName] = val

        if(!activityLogs[field?.altName])
            throw new Error(`${field?.name} is mandatory`)
    }

    
    if (fields?.submissionType?.length > 0){
        if(!payloadData['submissionType'])
            throw new Error(`Submission Type is mandatory`)
        activityLogs['submissionStatus'] = payloadData['submissionType']
    }

    if (fields?.contractualType?.length > 0){
        if(!payloadData['contractualType'])
            throw new Error(`Contractual Type is mandatory`) 
        activityLogs['contractualType'] = payloadData['contractualType']
    }

    activityLogs["updatedBy"] = userId
    
    

    return activityLogs
};




// get list of status that comes after current  status of candidateRequisition
export const getFilteredRequisitionStatus = (statusOptions, orignalData) => {
    if (!statusOptions || statusOptions?.length <= 0 || !orignalData?.statusName)
        return []

    return statusOptions.filter(elem => elem.name === orignalData.statusName)[0].statusOptions
    
}

export function convertDate(str) {
    if (str === "") {
        return "";
    }

    const date = new Date(str)

    return DateTime.fromJSDate(date).toISODate();

}


export const fetchDydamicFields = async (setLoader, statusOptions, submitDataFromModal, setSubmitDataFromModal, setRequiredfields, orignalData) => {

    setLoader(elem => ({ ...elem, loading: true }))

    let statusCode = filterFunc(statusOptions, (elem, match) => elem.name === match?.statusName, submitDataFromModal)[0]?.candidateStatusCode
    let placement_type = submitDataFromModal?.requisitionDetails?.placementType

    if (statusCode && placement_type) {
        const res = await getDynamicFieldsByStatus(statusCode, placement_type)
        setRequiredfields(res?.data)
        //  if selected status is new status of candidate requistion then show null fields
        if (orignalData?.statusName && submitDataFromModal?.statusName && orignalData.statusName !== submitDataFromModal.statusName
            && orignalData?.requisitionDetails?.reqreferenceId === submitDataFromModal?.requisitionDetails?.reqreferenceId
        ) {
            setSubmitDataFromModal((elem) => {
                let temp = { ...elem }

                let field = ["eventDate", "notes", "hourlyMargin", "endDate", "hourlyPayRate"]
                field.forEach((f) => {
                    temp[f] = ""
                })
                temp["displayDate"] = DateTime.now().toISODate()
                return temp
            })
        }
    }
    setLoader(elem => ({ ...elem, loading: false }))
}

// get candidate existing req
export const getCanReq = async (payload, id) => {

    let resp = (await getCandidateRequisitionByRequisitionIdAndCandidateEmail(payload))?.data?.data
    let data = { canReqExist: false }
    let recid = id


    let currentStatus = filterFunc(resp?.eventStatus || [], (element) => element.isCurrentlyUsed)?.at(-1)

    if (currentStatus && resp?.status?.candidateStatusCode === currentStatus?.candidateStatusCode) {
        data = resp
        let statusName = codeTofield(currentStatus?.candidateStatusCode)
        data = { ...data, canReqExist: true, statusName }
        recid = currentStatus?.createdBy
    }

    return { data, recid }
}